$(document).on('ready turbolinks:load', global.initInputFile);

global.initInputFile = function () {
  const $inputs = $('.input-file');

  $inputs.each(function () {
    const $this = $(this);
    const $filePreview = $this.find('.input-file__image > img');

    $this.on('change', ({ target }) => setPreview(target, $filePreview));
  });
}

function setPreview(input, $targetImg) {
  const [file] = input.files || [];

  if (file) {
    const isImage = file.type.split('/')[0] === 'image';
    const reader = new FileReader();

    reader.onload = event => isImage
      ? $targetImg.attr('src', event.target.result)
      : $targetImg.attr('src', $targetImg.data('default-src'));
    reader.readAsDataURL(input.files[0]);
  }
}
