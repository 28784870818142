global.flash = function ({ type, message = '', title = null, options = {} }) {
  const flashFn = toastr[type]
  const messages = `<span class='toast-text'>${[JSON.parse(message)].flat().join('<hr/>')}</span>`
  const newOptions = {
    timeOut: 20000,
    extendedTimeOut: 5000,
    newestOnTop: true,
    closeButton: true,
    progressBar: true,
    ...options
  }

  if (flashFn) flashFn(`${messages}`, title, newOptions)
}
