const $ = global.$
global.toastr = require('toastr/toastr')
global.Sortable = require('sortablejs/Sortable')

import 'javascripts/ui/modal'
import 'javascripts/ui/flash'
import 'javascripts/ui/tabs'
import 'javascripts/ui/textarea'
import 'javascripts/ui/file_input'

import 'javascripts/general'

$(document).on('ready turbolinks:load', ajaxBeforeSendInit)

function ajaxBeforeSendInit() {
  $(document).off('ajax:beforeSend')
  $(document).on('ajax:beforeSend', beforeSendListener)
}

global.beforeSendListener = function (e) {
  e.preventDefault()
  safeStartTurbolinksProgress()
  const [xhr, options] = e.detail

  delete options.beforeSend
  delete options.complete
  delete options.error
  delete options.success

  options.beforeSend = e => Rails.CSRFProtection(e)
  options.complete = e => safeStopTurbolinksProgress()
  window.$.ajax(options)
}

console.log('Scripts Loaded')
