$(document).on('ready turbolinks:load', () => {
  initTabs()
  $(window).on('resize', initTabs)
})

global.initTabs = function () {
  let isScrolling
  let highlightedTabs = new Map()
  // const { hash } = location

  const $header = $('.header')
  const $tabs = $('.content-tabs')
  const tabsScroll = $tabs.find('.content-tabs__scroll')[0]
  const $tabItems = $tabs.filter('.content-tabs--to-anchor').find('a')
  // const activeTab = getTab(hash)

  const headerOffset = $header.outerHeight()
  const offset = $header.outerHeight() + $tabs.outerHeight()

  $tabs.css('top', headerOffset)
  // activateTab(activeTab)

  $tabItems.on('click', scrollToTarget)
  $tabItems.each(function () {
    const { hash } = this || {}
    if (!hash) return

    highlightedTabs.set(this, false)
    const target = $(hash)[0]
    if (!target) return

    $(window).on('scroll', scrollHandler(this, target))
  })

  function activateTab(tab) {
    const { href } = tab || {}
    if (!href) return

    const $parent = $(tab).parent()
    const $siblings = $parent.siblings()

    $parent.addClass('active')
    $siblings.removeClass('active')
    // history.replaceState(null, null, href)

    const left = tab.offsetLeft + tab.offsetWidth / 2 - $tabs[0].clientWidth / 2
    tabsScroll.scroll({ left, behavior: 'smooth' })
  }

  function hilighteTab(tab, isShown) {
    highlightedTabs.set(tab, isShown)
    highlightedTabs.forEach((value, item) => {
      if (value) item.classList.add('highlight')
      else item.classList.remove('highlight')
    })
  }

  function scrollToTarget(event) {
    if (event) event.preventDefault()

    const { hash } = this || {}
    if (!hash) return

    const target = $(hash)[0]
    if (!target) retunr

    const top = target.offsetTop - offset;
    if (target) window.scroll({ top, behavior: 'smooth' })
  }

  function scrollHandler(tab, target) {
    let prevIsActive = false
    let prevIsShown = false

    return (event) => {
      const scrollTop = $(window).scrollTop() + offset
      const scrollBottom = scrollTop + window.innerHeight - offset
      const targetTop = target.offsetTop
      const targetBottom = targetTop + target.offsetHeight
      const isActive = scrollTop >= targetTop && scrollTop < targetBottom
      const isShown = targetTop < scrollBottom && targetBottom > scrollTop

      if (prevIsActive !== isActive && isActive) iterationStop(() => activateTab(tab), 200)
      if (prevIsShown !== isShown) hilighteTab(tab, isShown)

      prevIsActive = isActive
      prevIsShown = isShown
    }
  }

  function getTab(hash) {
    return $tabItems.filter((i, el) => el.hash === hash)[0]
  }

  function iterationStop(cb, delay) {
    window.clearTimeout( isScrolling );

    isScrolling = setTimeout(function() {
      if (typeof cb === 'function') cb()
    }, delay)
  }
}
