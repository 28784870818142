$(document).on('ready turbolinks:load', function() {
  global.initDrag()
  global.initDeliveryMethodHandler()
  global.initPhoneMask()

  $(document).on('change', '.setting_value input', function ({ target }) {
    Rails.fire(target.form, 'submit')
  })
});

global.initPhoneMask = function () {
  $('#order_client_phone').mask("+38 (099) 99-99-999")
}

global.initDrag = function () {
  const dragElements = document.querySelectorAll('[data-drag]');

  dragElements.forEach(function (element) {
    const group = element.dataset.drag
    const rawUrl = element.dataset.dragUrl

    const onEnd = ({ item, from, to, path, oldDraggableIndex, newDraggableIndex }) => {
      const isChanged = oldDraggableIndex !== newDraggableIndex || from !== to
      if (!isChanged) return;

      const id = item.dataset[`${group}Id`]
      const url = rawUrl.replace(':id', id)
      const newCategoryId = to.dataset?.categoryId
      const data = {
        [group]: {
          position: newDraggableIndex + 1,
          ...newCategoryId && { category_id: newCategoryId },
        }
      }

      $.ajax({
        url,
        method: 'PATCH',
        data,
        dataType: 'script',
        beforeSend: xhr => xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
      })
    }

    Sortable.create(element, {
      group,
      draggable: '.category, [data-item-id], .ingredient',
      handle: '.category__header, .ingredient__title, .item',
      animation: 150,
      delay: 100,
      delayOnTouchOnly: true,
      onEnd
    });
  });
}

global.initDeliveryMethodHandler = function () {
  const $checkboxes = $('.order_delivery_method input')

  $checkboxes.on('change', function ({ target }) {
    if (target.checked) activeMethod(target.value)
  })

  activeMethod($checkboxes.filter(':checked').val())

  function activeMethod (method) {
    const $inputs = $('[data-for]')
    const $totals = $('[data-delivery-method]')
    const $currentInputs = $inputs.filter(`[data-for*="${method}"]`)
    const $currentTotal = $totals.filter(`[data-delivery-method*="${method}"]`)

    $inputs.hide()
    $totals.hide()
    $currentInputs.show()
    $currentTotal.show()
  }
}

global.notifySoundLoop = function () {
  const audio = $('#notify-sound')[0]
  let loop

  return {
    start: () => {
      loop = setInterval(() => audio.play(), 500)
    },
    stop: () => clearInterval(loop)
  }
}
